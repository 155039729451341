import { R } from "@mobily/ts-belt";
import { useRouter } from "next/router";
import { useRef, useState, type ElementRef, useEffect } from "react";
import type { Form, DefaultFormType } from "./util/FormType";
import { 링크 } from "./데이터/링크";

import { FormField, phoneFormatter } from "./FormField";
import { asx } from "./util/applyStyle";
import styles from "./CTA_간편가입_Desktop.module.css";

import { useTracker } from "./util/Tracker";

const as = asx(styles);

export type 간편가입_폼타입 = "phone";
type 간편가입RequiredField = "phone";
type 간편가입FormState = Form<간편가입RequiredField, undefined, true>;
type 간편가입FormDefaultState = DefaultFormType<
  간편가입RequiredField,
  undefined,
  true
>;

const defaultForm: 간편가입FormDefaultState = {
  phone: R.Error(""),
  privacyAgree: true,
  __state: "error",
};

const makeFormState = (phone: R.Result<string, string>): 간편가입FormState => {
  return {
    phone,
    privacyAgree: true,
    __state: R.isOk(phone) ? "ok" : "error",
  };
};

export const CTA_간편가입_Desktop = () => {
  const [formValues, setFormValues] = useState<간편가입FormState>(defaultForm);
  const tracker = useTracker();
  const ctaRef = useRef<ElementRef<"div">>(null);

  const router = useRouter();
  const onClick = async () => {
    tracker.trackingClick({
      depth1: "HOME",
      depth2: "회원가입",
      depth3: "마켓봄FL",
      indicator: "회원가입",
      scroll: window.scrollY,
    });

    router.push(
      `${링크.마켓봄_간편가입_실험_링크_PC}/?hp=${formValues.phone._0}`
    );
  };
  useEffect(() => {
    const trackscroll = () => {
      if (ctaRef.current) {
        if (window.scrollY > 420) {
          ctaRef.current.classList.add(as("show"));
        } else {
          ctaRef.current.classList.remove(as("show"));
        }
      }
    };
    window.addEventListener("scroll", trackscroll);
    return () => {
      window.removeEventListener("scroll", trackscroll);
    };
  }, [ctaRef]);

  const submit = () => {
    formValues.__state === "ok" && onClick();
  };

  return (
    <div className={as("subHeader")} ref={ctaRef}>
      <div className={as("simple-signup")}>
        <div style={{ flex: 1, paddingLeft: "24px" }}>
          <FormField<간편가입_폼타입>
            label=""
            errorLabel=""
            fieldName="phone"
            validateFunction="phone"
            onUpdate={(newValue) => {
              setFormValues(() => makeFormState(newValue));
            }}
            placeholder="010-0000-0000"
            formatter={phoneFormatter}
            onEnter={submit}
            styles={styles}
          />
        </div>

        <button type="submit" onClick={onClick} className={as("submit")}>
          회원가입
        </button>
      </div>
    </div>
  );
};
