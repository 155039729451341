import Link from "next/link";
import Image from "next/image";
import Section1Image from "/public/assets/pc/images/pic/img_section1.webp";
import Section1BackgroundImage from "/public/assets/pc/images/pic/img_section_bg1.webp";
import { 무료체험기간 } from "./데이터/무료체험기간";
import { 링크 } from "./데이터/링크";
import { useTracker } from "./util/Tracker";
import { useContext, useState } from "react";
import { R, pipe } from "@mobily/ts-belt";
import { FormField, phoneFormatter } from "./FormField";
import type { Form, DefaultFormType } from "./util/FormType";
import styles from "./랜딩_섹션1_Desktop2.module.css";
import { asx } from "./util/applyStyle";
import router from "next/router";
import { GrowthBookCTX, getValue } from "./util/GrowthBookCTX";

const as = asx(styles);

export type 간편가입_폼타입 = "phone";
type 간편가입RequiredField = "phone";
type 간편가입FormState = Form<간편가입RequiredField, undefined, true>;
type 간편가입FormDefaultState = DefaultFormType<
  간편가입RequiredField,
  undefined,
  true
>;

const defaultForm: 간편가입FormDefaultState = {
  phone: R.Error(""),
  privacyAgree: true,
  __state: "error",
};

const makeFormState = (phone: R.Result<string, string>): 간편가입FormState => {
  return {
    phone,
    privacyAgree: true,
    __state: R.isOk(phone) ? "ok" : "error",
  };
};

const CTA = () => {
  const [formValues, setFormValues] = useState<간편가입FormState>(defaultForm);
  const tracker = useTracker();
  const onClick = async () => {
    tracker.trackingClick({
      depth1: "HOME",
      depth2: "회원가입",
      depth3: "마켓봄",
      indicator: "회원가입",
      scroll: window.scrollY,
    });

    router.push(
      `${링크.마켓봄_간편가입_실험_링크_PC}/?hp=${formValues.phone._0}`
    );
  };

  const onSubmit = () => {
    if (formValues.__state === "ok") {
      onClick();
    }
  };

  return (
    <div className={styles["subHeader"]}>
      <div className={as("simple-signup  bb-[3] b-[0]")}>
        <div style={{ flex: 1, paddingLeft: "12px" }}>
          <FormField<간편가입_폼타입>
            label=""
            errorLabel=""
            fieldName="phone"
            validateFunction="mobilePhone"
            onUpdate={(newValue) => {
              setFormValues(() => makeFormState(newValue));
            }}
            placeholder="010-0000-0000"
            formatter={phoneFormatter}
            onEnter={onSubmit}
            styles={styles}
          />
        </div>

        <button onClick={onClick} className={as("submit")}>
          회원가입
        </button>
      </div>
    </div>
  );
};

export const 랜딩_섹션1_Desktop2 = () => {
  const tracker = useTracker();
  const featureValue = useContext(GrowthBookCTX);
  const simplifiedRegister = pipe(
    featureValue,
    getValue("simplified-register")
  );

  return (
    <div
      className="section section1"
      style={{
        background: `url(${Section1BackgroundImage.src}) no-repeat center top`,
        height: "780px!important",
      }}
    >
      <div className="inner">
        <dl>
          <dd>
            유통관리가 편해지는
            <br />
            수발주 마켓봄
          </dd>
        </dl>
        {simplifiedRegister ? (
          <div className={as("cta-wrap")}>
            <span>휴대폰 번호만으로 빠르게, 무료로 시작하세요!</span>
            <CTA />
          </div>
        ) : (
          <div className="btn-wrap">
            <Link
              href={링크.마켓봄_회원가입}
              onClick={() =>
                tracker.trackingClick({
                  depth1: "HOME",
                  depth2: "회원가입",
                  depth3: "마켓봄",
                  indicator: "회원가입",
                })
              }
            >
              {`${무료체험기간.마켓봄} 무료로 써보기`}
            </Link>
          </div>
        )}
        <div className="pic">
          <Image
            src={Section1Image}
            alt=""
            priority
            quality={100}
            width={815}
            unoptimized
          />
        </div>
      </div>
    </div>
  );
};
