import styles from "./CTA_회원가입_Mobile2.module.css";
import { 링크 } from "./데이터/링크";
import { useTracker } from "./util/Tracker";
import { asx } from "./util/applyStyle";

const as = asx(styles);
export const CTA_회원가입2 = () => {
  const tracker = useTracker();

  return (
    <>
      <div className={styles["cta-spacer"]} />

      <div className={as("cta docked")}>
        <a
          href={링크.마켓봄_간편가입_실험_링크_모바일}
          className={as("btn btn-join touch content")}
          onClick={() => {
            tracker.trackingClick({
              depth1: "HOME",
              depth2: "회원가입",
              depth3: "마켓봄",
              indicator: "회원가입",
            });
          }}
        >
          <span className={styles["cta-button-text"]}>회원가입</span>
          {/* <div className={as("overlay")} />
          <div className={as("signUpCatchPhrase")}>
            <span>{"우리 회사에 잘 맞을까?"}</span>
            <span>{"5초 만에 시작하기"}</span>
            <span>{"우리 회사에 잘 맞을까?"}</span>
          </div> */}
        </a>
      </div>
    </>
  );
};
