"use client";

import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { useTracker } from "./util/Tracker";
import { 마켓봄피쳐_목록, type 마켓봄피쳐 } from "./데이터/마켓봄피쳐";
import { BoldableTextList, BoldableTextMultiline } from "./BoldableText";
import 접기_화살표_아이콘 from "/public/assets/mobile/images/icon/icon_arrow_up.png";
import 더보기_화살표_아이콘 from "/public/assets/mobile/images/icon/icon_arrow_down.png";

type 섹션Props = {
  마켓봄피쳐: 마켓봄피쳐;
};

const 섹션3_Row = ({ 마켓봄피쳐 }: 섹션Props) => {
  const {
    제목: { 단점, 장점 },
    설명,
    이미지,
  } = 마켓봄피쳐_목록()[마켓봄피쳐];

  return (
    <div className="white-box">
      <h4>
        <BoldableTextList list={단점} />
        <br />
        <span className="fc1">
          <BoldableTextList list={장점} />
        </span>
      </h4>
      <div style={{ margin: "0px auto", width: "fit-content" }}>
        <BoldableTextMultiline list={설명} align="center" />
      </div>
      <div className="pic">
        <Image src={이미지} width={540} alt="" />
      </div>
    </div>
  );
};

export const 마켓봄_장점_Mobile = () => {
  const tracker = useTracker();
  const [foldState, setFoldState] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [isTracked, setIsTracked] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // 요소가 뷰포트에 들어오는 경우에만 트래킹
          if (entry.isIntersecting && !isTracked) {
            tracker.trackingScroll({
              depth1: "HOME",
              depth2: "문제점",
            });
            setIsTracked(true); // 요소가 한 번 트래킹되면 다시 트래킹되지 않도록 상태를 변경
          }
        });
      },
      {
        rootMargin: "0px", // 필요에 따라 rootMargin을 조정할 수 있습니다.
        threshold: 0.3, // 요소가 10% 보일 때 콜백이 실행되도록 threshold 조정
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [isTracked, tracker]); // 의존성 배열에 isTracked와 tracker를 추가
  return (
    <div className="section section3" ref={ref}>
      <h3>아직도 이런 문제를 가지고 계신가요?</h3>
      <섹션3_Row 마켓봄피쳐="거래처주문앱" />
      <섹션3_Row 마켓봄피쳐="자동취합" />
      {foldState && (
        <div className="toggle-wrap">
          <섹션3_Row 마켓봄피쳐="미수금0" />
          <섹션3_Row 마켓봄피쳐="무제한추가" />
          <섹션3_Row 마켓봄피쳐="맞춤상품" />
          <섹션3_Row 마켓봄피쳐="거래처별원클릭단가" />
          <섹션3_Row 마켓봄피쳐="유통사앱즉시확인" />
          <섹션3_Row 마켓봄피쳐="언제어디서나" />
          <섹션3_Row 마켓봄피쳐="원격세팅지원" />
          <섹션3_Row 마켓봄피쳐="하나로관리" />
          <섹션3_Row 마켓봄피쳐="인쇄커스텀제공" />
        </div>
      )}
      <button
        type="button"
        className="btn-detail"
        onClick={() => {
          setFoldState(!foldState);
          tracker.trackingClick({ depth1: "HOME", depth2: "다른 문제점 더 보기" });
        }}
        style={{
          background: `url(${foldState ? 접기_화살표_아이콘.src : 더보기_화살표_아이콘.src}) no-repeat right ${foldState ? "73px" : "30px"} center`,
        }}
      >
        {foldState ? "접기" : "다른 문제점 더 보기"}
      </button>
    </div>
  );
};
