import type { StaticImageData } from "next/image";
import 섹션3이미지1 from "/public/assets/pc/images/pic/img_section3_1.webp";
import 섹션3이미지2 from "/public/assets/pc/images/pic/img_section3_2.webp";
import 섹션3이미지3 from "/public/assets/pc/images/pic/img_section3_3.png";
import 섹션3이미지4 from "/public/assets/pc/images/pic/img_section3_4.webp";
import 섹션3이미지5 from "/public/assets/pc/images/pic/img_section3_5.webp";
import 섹션3이미지6 from "/public/assets/pc/images/pic/img_section3_6.webp";
import 섹션3이미지7 from "/public/assets/pc/images/pic/img_section3_7.webp";
import 섹션3이미지8 from "/public/assets/pc/images/pic/img_section3_8.webp";
import 섹션3이미지9 from "/public/assets/pc/images/pic/img_section3_9.webp";
import 섹션3이미지10 from "/public/assets/pc/images/pic/img_section3_10.png";
import 섹션3이미지11 from "/public/assets/pc/images/pic/img_section3_11.png";
import { match } from "ts-pattern";
import { A, pipe } from "@mobily/ts-belt";
import React, { useEffect, useRef, useState } from "react";
import { useTracker } from "./util/Tracker";
import styles from "./랜딩_섹션3_Desktop.module.css";
import { asx } from "./util/applyStyle";

const as = asx(styles);

type fontWeight = "normal" | "bold";

type Text = {
  text: string;
  fontWeight: fontWeight;
};

type 섹션Props = {
  이미지: StaticImageData;
  제목: {
    plain: Text[];
    highlighted: Text[];
  };
  설명: Text[][];
  방향: "left" | "right";
};

const text = ({ text, fontWeight }: Text) => {
  return match(fontWeight)
    .with("normal", () => <>{text}</>)
    .with("bold", () => (
      <strong className="bold" key={text}>
        {text}
      </strong>
    ))
    .exhaustive();
};

const 섹션3_Row = ({
  이미지,
  제목: { plain, highlighted },
  설명,
}: 섹션Props) => {
  return (
    <div
      className={as("row type1 row-right")}
      style={{ background: `url(${이미지.src}) no-repeat right top` }}
    >
      <dl>
        <dt>
          {plain.map((_txt) => (
            <React.Fragment key={_txt.text}>{text(_txt)}</React.Fragment>
          ))}
          <br />
          <span className="fc1">
            {highlighted.map((_txt) => (
              <React.Fragment key={_txt.text}>{text(_txt)}</React.Fragment>
            ))}
          </span>
        </dt>
        <dd>
          {pipe(
            설명,
            A.map((설명_줄) =>
              설명_줄.map((_txt) => (
                <React.Fragment key={_txt.text}>{text(_txt)}</React.Fragment>
              ))
            ),
            A.flat,
            A.intersperse(<br key={Math.random()} />)
          )}
        </dd>
      </dl>
    </div>
  );
};

export const 랜딩_섹션3_Desktop = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [fireOnce, setFireOnce] = useState(false);
  const tracker = useTracker();

  useEffect(() => {
    const 특장점listener = () => {
      setIsVisible(window.scrollY > (ref.current?.offsetTop ?? 99999));
    };

    window.addEventListener("scroll", 특장점listener);
    return () => {
      window.removeEventListener("scroll", 특장점listener);
    };
  }, [ref, isVisible]);

  useEffect(() => {
    console.log(fireOnce, isVisible);
    if (!fireOnce && isVisible) {
      tracker.trackingScroll({
        depth1: "HOME",
        depth2: "문제점",
      });
      setFireOnce(true);
    }
  }, [isVisible, fireOnce, tracker]);
  return (
    <div className={as("section section3")} ref={ref}>
      <div className="inner">
        <h3>아직도 이런 문제를 가지고 계신가요?</h3>
        {/* 아래와 같은 방법으로 리팩토링 구상 중. */}
        <섹션3_Row
          이미지={섹션3이미지1}
          제목={{
            plain: [
              { text: "카톡,문자,전화로 ", fontWeight: "normal" },
              { text: "불편한 주문", fontWeight: "bold" },
            ],
            highlighted: [
              { text: "거래처(식당) 주문 앱으로 ", fontWeight: "normal" },
              { text: "간단한 주문", fontWeight: "bold" },
            ],
          }}
          설명={[
            [
              {
                text: "거래처 주문용 모바일 앱 지원으로",
                fontWeight: "normal",
              },
            ],
            [
              {
                text: "식당, 가맹점에서 간편하게 주문이 가능합니다.",
                fontWeight: "normal",
              },
            ],
          ]}
          방향={"right"}
        />
        <div
          className={as("row type2")}
          style={{ background: `url(${섹션3이미지2.src}) no-repeat left top` }}
        >
          <dl>
            <dt>
              반복되는 장시간의 <strong className="bold">수발주 취합</strong>
              <br />
              <span className="fc1">
                마켓봄은
                <strong className="bold"> 주문 즉시 자동 취합</strong>
              </span>
            </dt>
            <dd>
              매일 반복되던 장시간의 수발주 처리
              <br />
              (전화, 문자, 카톡 등)를
              <strong className="bold"> 거래처의 간편한</strong>
              <br />
              <strong className="bold">주문 시스템으로</strong> 즉시 자동
              취합합니다.
            </dd>
          </dl>
        </div>
        <div
          className={as("row type3 row-right")}
          style={{ background: `url(${섹션3이미지3.src}) no-repeat left center` }}
        >
          <dl>
            <dt>
              외상 거래로 <strong className="bold">미수금 증가</strong>
              <br />
              <span className="fc1">
                마켓봄은 <strong className="bold">미수금 ZERO!</strong>
              </span>
            </dt>
            <dd>
              <strong className="bold">다양한 선결제 방식</strong>
              (가상계좌, 신용카드)과
              <br />
              <strong className="bold">미수금 관리 기능</strong>
              (한도 설정, 결제일 설정, 주문제한,
              <br />
              문자알림)으로
              <strong className="bold"> 외상거래를 감소</strong>시킬 수 있습니다.
            </dd>
          </dl>
        </div>
        <div
          className={as("row type4")}
          style={{ background: `url(${섹션3이미지4.src}) no-repeat right top` }}
        >
          <dl>
            <dt>
              거래처 추가 시 <strong className="bold">비싸지는 요금제</strong>
              <br />
              <span className="fc1">
                마켓봄은 거래처
                <strong className="bold"> 무제한 무료</strong>
              </span>
            </dt>
            <dd>
              마켓봄 월 최대 121,000원, 가입비 무료, 세팅비 무료
              <br />
              거래처 추가 비용 없이 무제한 생성이 가능합니다.
            </dd>
          </dl>
        </div>
        <div
          className={as("row type5 row-right")}
          style={{ background: `url(${섹션3이미지5.src}) no-repeat right top` }}
        >
          <dl>
            <dt>
              비거래 <strong className="bold">상품 영업의 어려움</strong>
              <br />
              <span className="fc1">
                마켓봄은 맞춤 상품 노출로
                <strong className="bold"> 매출 증가</strong>
              </span>
            </dt>
            <dd>
              <strong className="bold">비거래 신규 상품</strong>을 거래처의
              <strong className="bold"> 주문 시스템에 노출</strong>,<br />
              신규 거래를 성사시켜 매출을 증가시킵니다.
              <br />
              (온라인 전단지, 쇼핑몰 운영 효과)
            </dd>
          </dl>
        </div>
        <div
          className={as("row type6")}
          style={{ background: `url(${섹션3이미지6.src}) no-repeat left top` }}
        >
          <dl>
            <dt>
              불편했던 <strong className="bold">거래처별 단가관리</strong>
              <br />
              <span className="fc1">
                마켓봄은 <strong className="bold">원클릭 관리</strong>
              </span>
            </dt>
            <dd>
              단가그룹으로 여러개의 단가를 설정하여
              <br />
              식당,가맹점 마다 각각 다른 가격에 판매가 가능합니다.
            </dd>
          </dl>
        </div>
        <div
          className={as("row type7 row-right")}
          style={{ background: `url(${섹션3이미지7.src}) no-repeat right top` }}
        >
          <dl>
            <dt>
              실시간 확인이 어렵던
              <strong className="bold"> 매출/매입 조회</strong>
              <br />
              <span className="fc1">
                마켓봄은
                <strong className="bold"> 유통사 앱으로 즉시 확인</strong>
              </span>
            </dt>
            <dd>
              유통사 주문확인용
              <strong className="bold"> 모바일 앱 제공</strong>으로
              <br />
              실시간으로 매출과 매입의 상세내역까지
              <br />
              확인할 수 있습니다.
            </dd>
          </dl>
        </div>
        <div
          className={as("row type8")}
          style={{ background: `url(${섹션3이미지8.src}) no-repeat left top` }}
        >
          <dl>
            <dt>
              프로그램이 설치된 장소 외
              <strong className="bold"> 업무 불가능</strong>
              <br />
              <span className="fc1">
                마켓봄은 설치 없이
                <strong className="bold"> 언제 어디서나 접속</strong>
              </span>
            </dt>
            <dd>인터넷이 가능한 공간이면 언제 어디서든 업무가 가능합니다.</dd>
          </dl>
        </div>
        <div
          className={as("row type9 row-right")}
          style={{ background: `url(${섹션3이미지9.src}) no-repeat right top` }}
        >
          <dl>
            <dt>
              복잡하고 오래 걸리는 <strong className="bold">초기 세팅</strong>
              <br />
              <span className="fc1">
                마켓봄은
                <strong className="bold"> 긴급 방문과 원격 세팅 지원</strong>
              </span>
            </dt>
            <dd>
              영업 사원이 방문하여 교육을 하고,
              <br />
              기존 전산 데이터를 이관하고,
              <br />
              원격으로도 초기 세팅을 지원해드립니다.
              <br />
              <strong className="bold">초기세팅 비용 모두 무료!</strong>
            </dd>
          </dl>
        </div>
        <div
          className={as("row type10")}
          style={{ background: `url(${섹션3이미지10.src}) no-repeat right center` }}
        >
          <dl>
            <dt>
              <strong className="bold"> 유통사마다 프로그램</strong> 사용
              <br />
              <span className="fc1">
                마켓봄은
                <strong className="bold"> 하나로 관리!</strong>
              </span>
            </dt>
            <dd>
              유통사 다중구조로 관리중인 유통사를 연결하여
              <br />
              <strong className="bold">하나의 프로그램으로 동시에 관리</strong>
              가 가능합니다.
            </dd>
          </dl>
        </div>
        <div
          className={as("row type11 row-right")}
          style={{ background: `url(${섹션3이미지11.src}) no-repeat left center` }}
        >
          <dl>
            <dt>
              프로그램마다
              <strong className="bold"> 다른 인쇄설정</strong>
              <br />
              <span className="fc1">
                마켓봄은
                <strong className="bold"> 직접 커스텀 제공!</strong>
              </span>
            </dt>
            <dd>
              <strong className="bold">화면별 인쇄 설정</strong>
              으로 각 상황에 맞는 최적화된
              <br />
              인쇄 설정 가능하며,
              <strong className="bold"> 거래명세표 커스텀</strong>으로
              <br />
              기존 거래명세표와 동일하게 출력이 가능합니다.
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
};
